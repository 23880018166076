import React from "react"
import style from "./heading.mod.scss"

const Heading = ({ heading, subHeading }) => {
  return (
    <div className={style.heading}>
      <h1>{heading}</h1>
      {subHeading && <p>{subHeading}</p>}
    </div>
  )
}

export default Heading
