import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import { transformWorkout } from "@helpers/favouritesHelper"
import Icon from "@elements/Icon"
import { HelmetDatoCms } from "gatsby-source-datocms"
import CardsContainer from "@global/cards/CardsContainer"
import Block from "@global/layout/Block"
import Heading from "@elements/Heading"
import Banner from "@global/layout/Banner"
import Card from "@global/cards/Card"
import Content from "@elements/Content"
import EquipmentList from "@workout/EquipmentList"

const WorkoutCategories = ({ data, pageContext: { title, parent } }) => {
  const category = data.category
  const workouts = data.workouts

  let workoutsToDisplay = 6

  // switch (category.slug) {
  //   case "calme":
  //   case "ignite":
  //   case "power-session":
  //   case "introductory-sculpt":
  //   case "sculpt":
  //   case "pregnancy-power-session":
  //     workoutsToDisplay = 12
  //     break
  //   case "steady-state-cardio":
  //     workoutsToDisplay = 6
  //     break
  //   case "speed-session":
  //   case "core-connect":
  //     workoutsToDisplay = 21
  //     break
  //   default:
  //     workoutsToDisplay = 9
  // }

  switch (category.slug) {
    case "introductory-sculpt":
    case "pregnancy-power-session":
      workoutsToDisplay = 12
      break
    case "steady-state-cardio":
      workoutsToDisplay = 6
      break
    case "core-connect":
    case "meditation":
      workoutsToDisplay = 99
      break
    case "pregnancy-yoga":
    case "pregnancy-power-session":
    case "introductory-sculpt":
    case "cool-downs":
    case "stretching":
      workoutsToDisplay = 9
      break
    default:
      workoutsToDisplay = 15
  }

  return (
    <Layout parent={parent} title={title}>
      <HelmetDatoCms
        defer={false}
        title={`${title}${data.seo.globalSeo.titleSuffix}`}
      />
      <Banner image={category.banner} />
      <Block padding="double-top" gutters={true} maxWidth="inner">
        <Heading
          heading={category.longTitle}
          subHeading={category.skillLevel}
        />
        <Block desktopFlex={true}>
          <Icon
            modifier="dk--icon--category"
            type="cook"
            content={`${category.averageDuration} min - average workout length`}
          />
          {category.averageSweatometer && (
            <Icon
              modifier="dk--icon--category"
              type="drop"
              content={`${category.averageSweatometer}/5 - average Sweatometer rating`}
            />
          )}
        </Block>
      </Block>
      <Block gutters={true} padding="both" maxWidth="inner">
        <EquipmentList
          items={category.commonEquipment}
          modifier="align-left"
          size="small"
        />
        <Block padding="bottom" desktopPadding="top-double-only">
          <Content html={category.descriptionNode.childMarkdownRemark.html} />
        </Block>
      </Block>
      <Block gutters={true} maxWidth="list-inner">
        <CardsContainer>
          {workouts.nodes.slice(0, workoutsToDisplay).map(node => (
            <Card
              {...transformWorkout(node)}
              modifier="horizontal-for-small"
              key={`workoutCat-${node.title}`}
            />
          ))}
        </CardsContainer>
      </Block>
    </Layout>
  )
}

export const query = graphql`
  query workoutCategoryById($id: String) {
    category: datoCmsWorkoutCategory(id: { eq: $id }) {
      id
      slug
      averageDuration
      averageSweatometer
      description
      title
      skillLevel
      longTitle
      banner {
        gatsbyImageData(imgixParams: { fit: "crop", w: "2400", h: "1200" })
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      commonEquipment {
        name
        id
        icon {
          url
        }
      }
    }

    workouts: allDatoCmsWorkout(
      filter: { category: { id: { eq: $id } }, archived: { ne: true } }
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        ...WorkoutCard
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
  }
`

export default WorkoutCategories
